.website__biotext {
    width: 100%;
    height: 880px;
    background-color: #03080a;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.website__biotext-bio {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-size: 64px;
    line-height: 77px;
    color: #ecf4f9;
    text-align: center;
    position: relative;
    height: 10%;
    width: 20%;
    left: 40%;
    top: 15%;
}


.website__biotext-description {
    width: 800px;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-size: 3vw; /*40px;*/
    line-height: 77px;
    color: #ecf4f9;
    text-align: center;
    position: relative;
    height: 10%;
    width: 50%;
    left: 25%;
    top: 25%;
}

@media screen and (max-width: 768px) {
    .website__biotext-bio { 
        height: 5%;
        font-size: 10vw;
    }
    .website__biotext-description {
        font-size: 4vw;
        padding: 0;
    }
}