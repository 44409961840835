.website__infobar {
    align-items: left;
    position: relative;
    width: 20%;
    height: 10%;
    margin-top: 2%; 
    float: right
}

.website__infobar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: left;
    padding: 0px;
    gap: 10px;
}

.website__infobar-links_container {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 55px;
}

/*
* Disable color change of unvisited hyperlinks; preserve text decoration (underline)
*/
a {
    color: inherit;
}

@media screen and (max-width: 768px) {
    .website__infobar {
        float: none;
        padding: 10%;
    }
}