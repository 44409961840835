.website__experience {
    width: 100%;
    height: 880px;
    background-image: url("/src/assets/breadboard.png");
    /* background-attachment: fixed; */
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.website__experience-name {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-size: 64px; 
    line-height: 100%; /* 77 px fixed */
    color: #100606;
    text-align: left;
    position: relative;

    height: 10%;
    width: 100%;
    top: 5%; 
    left: 3%;
}

.website__experience-infobar{ 
    color: #fcfafa;
}

.website__experience-navbar {
    color: #f3f0f0;
}

@media screen and (max-width: 768px) {
    .website__experience-name { 
        font-size: 14vw;
        padding: 1%;
        top: 5%;
        padding-bottom: 10%;
        align-items: center;
        text-align: center;
    }
}