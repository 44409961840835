* {
    box-sizing: border-box;
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
}

/*
* To prevent alignment problem on iOS/Safari, add a standardized body. See https://stackoverflow.com/questions/4617872/white-space-showing-up-on-right-side-of-page-when-background-image-should-extend
*/
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

.root {
    height: 8780px;
    width: 1440px;
}

/*
* Set up resizing and mobile behavior - see https://stackoverflow.com/questions/4935114/align-div-left-with-minimum-margin-that-floats-offscreen-on-resize 
*/
/* @media screen and (max-width: 1000px) {
    *{
        margin-left: 0; 
        float: left; 
        width: 100%;
    } 
}  */