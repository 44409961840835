.website__achievementstext {
    width: 100%;
    height: 880px;
    background-color: #010101;
    text-align: center;
    position: relative;
}

.website__achievementstext-achievements {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-size: 64px; 
    line-height: 100%; /* 77 px fixed */
    color: #fff9f9;
    text-align: center;
    position: relative;

    height: 10%;
    width: 100%;
    top: 8%; 
    left: 3%;
}


.website__achievementstext-description {
    width: 100%;
    height: 1000px;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-size: 30px;
    font-size: 30px;
    line-height: 40px;
    color: #fff9f9;
    text-align: center;
    position: relative;
    width: 70%;
    height: 30%;
    top: 19%;
    left: 15%; 
    margin-top: 2%;
}

@media screen and (max-width: 768px) {
    .website__achievementstext-achievements { 
        font-size: 14vw;
        padding: 1%;
        top: 10%;
        align-items: center;
        text-align: center;
    }
    .website__achievementstext-description {
        font-size: 4vw;
    }
}