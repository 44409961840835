.website__experiencetext {
    width: 100%;
    height: 880px;
    background-color: #050607;
    text-align: center;
    position: relative;
}

.website__experiencetext-experience {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-size: 64px; 
    line-height: 100%; /* 77 px fixed */
    color: #ffffff;
    text-align: center;
    position: relative;

    height: 10%;
    width: 100%;
    top: 8%; 
    left: 3%;
}


.website__experiencetext-description {
    width: 100%;
    height: 1000px;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-size: 30px;
    line-height: 40px;
    color: #ffffff;
    text-align: left;
    padding-left: 20px;
    position: relative;
    padding: 25px;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.website__experiencetext-description_title {
    font-weight: bold;
}


@media screen and (max-width: 940px) {
    .website__experiencetext{
        min-height: 1200px;
        padding-bottom: 40%;
    }
    .website__experiencetext-description {
        top: 55%;
        font-size: 4vw;
    }
    .website__experiencetext-experience { 
        font-size: 14vw;
        padding: 1%;
        top: 5%;
        align-items: center;
        text-align: center;
    }
}