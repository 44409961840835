.website__thanks {
    width: 100%;
    height: 880px;
    background-image: url("/src/assets/flatirons.png");
    background-attachment: fixed;
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.website__thanks-name {
    position: relative;
    width: 45%;
    height: 10%;
    left: 30%;
    top: 52%;

    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-size: 64px;
    line-height: 77px;

    color: #ffcdcd;
}

.website__thanks-infobar{ 
    color: #100606;
}

.website__thanks-navbar {
    color: #967474;
}

@media screen and (max-width: 940px) {
    .website__thanks-name {
        font-size: 8vw;
        padding: 1%;
        top: 2%;
        color: #1b1515;
        padding-bottom: 10%;
        align-items: center;
        text-align: center;
        width: 100%;
        left: 4%;
    }
}