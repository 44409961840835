.website__projects {
    width: 100%;
    height: 920px;
    background-image: url("/src/assets/duck_road.png");
    /* background-attachment: fixed; */
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.website__projects-name {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-size: 64px; 
    color: #1b1818;
    line-height: 100%; /* 77 px fixed */
    text-align: left;
    position: relative;

    height: 10%;
    width: 100%;
    top: 5%; 
    left: 3%;
}

.website__projects-infobar {
    color: #100606;
}

.website__projects-navbar{
    color: #100606;
}

@media screen and (max-width: 768px) {
    .website__projects-name { 
        font-size: 14vw;
        padding: 1%;
        top: 5%;
        padding-bottom: 10%;
        align-items: center;
        text-align: center;
    }
}