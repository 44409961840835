.website__achievements {
    width: 100%;
    height: 880px;
    background-image: url("/src/assets/quickdraw.png");
    /* background-attachment: fixed; */
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.website__achievements-name {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-size: 64px; 
    line-height: 100%; /* 77 px fixed */
    color: #ffffff;
    text-align: left;
    position: relative;

    height: 10%;
    width: 100%;
    top: 5%; 
    left: 3%;
}

.website__achievements-infobar{ 
    color: #ffffff;
}

.website__achievements-navbar {
    color: #ffffff;
}

.website__achievements:hover {
    color: black;
}
  
.website__achievments:hover:before {
    display: block;
}

@media screen and (max-width: 768px) {
    .website__achievements-name { 
        font-size: 14vw;
        padding: 1%;
        top: 5%;
        padding-bottom: 10%;
        align-items: center;
        text-align: center;
    }
}